<template>
  <div id="waitingroom">
    <div class="card">
      <h2>欢迎来到王医生的候诊室！</h2>
    </div>

    <div class="card">
      <div v-if="pos_in_queue != 0">
        <p>您前面还有 {{pos_in_queue}} 人在等候</p>
        <p>预计等候时间为 {{waitingtime(pos_in_queue)}} 分钟</p>
      </div>
      <div v-else>
        <p style="color: green">医生已准备好为您提供服务</p>
        <p>请点击下方按钮启动视频就诊</p>
      </div>

    </div>
    <div class="card">
      <div id=smallTip>
        <h4>小贴士：</h4>
        <p><i class="fas fa-check-circle fa-1x"></i> 使用稳定的wifi信号</p>
        <p><i class="fas fa-check-circle fa-1x"></i> 关闭暂时不用的应用程序或网页</p>
        <p><i class="fas fa-check-circle fa-1x"></i> 更新到浏览器的最新版本</p>
        <p><i class="fas fa-check-circle fa-1x"></i> 请保持电话畅通，诊所可能会向您致电并确认信息</p>
        <p><i class="fas fa-check-circle fa-1x"></i> 如遇到视频遇到问题请致电诊所</p>
      </div>
    </div>
    <button v-if="pos_in_queue == 0" @click="enterSession">进入视频</button>
    <button style="background-color: grey" v-else>请耐心等待</button>
    <button style="background-color: red" @click="abortSession">放弃等候并离开</button>

  </div>
</template>

<script>
import {
  Cache
} from "aws-amplify";
import router from "@/router";
import {
  components
} from "aws-amplify-vue";
import axios from "axios";

export default {
  name: "WaitingRoomView",
  components: {
    Cache,
    components
  },
  data() {
    return {
      queryInterval_ms: 1000,
      pos_in_queue: "",
      session_abort: false,
    };
  },
  computed: {
    session_id() {
      var rtn_sid = Cache.getItem("session_id");
      console.log("getting sid:", rtn_sid);
      return rtn_sid;
    }
  },
  methods: {
    setPollingInterval() {
      //using if-immediate to improve lookup preformance
      if (this.pos_in_queue >= 5) {
        this.queryInterval_ms = 60000
      } else if (this.pos_in_queue < 5 && this.pos_in_queue > 0) {
        this.queryInterval_ms = this.pos_in_queue * 6000
      } else if (this.pos_in_queue == 0) {
        this.queryInterval_ms = -1
      } 
      else{
        this.abortSession()
      }
    },
    pollQueue() {

      this.getQueue()
      if (this.queryInterval_ms > 0 && !this.session_abort) {
        console.log("sending another request it", this.queryInterval_ms, this.pos_in_queue)
        setTimeout(this.pollQueue, this.queryInterval_ms)
      }
    },

    getQueue() {
      axios
        .post("https://api.drwang.care/prod/patient/getwaitq", {
          session_id: this.session_id
        })
        .then(response => {
          let body_json_obj = JSON.parse(response.data.body)
          console.log(response)
          this.pos_in_queue = body_json_obj["queue_in_front"];
          if (this.pos_in_queue == 0) {
            this.saveVideoConfig(body_json_obj);
          }
          this.setPollingInterval()
        })
        .catch(e => {
          console.log(e);
          this.queryInterval_ms = -1
          alert("Something Went Wrong, Please try again")
          Cache.clear()
        });

    },

    waitingtime: function (pos_in_queue) {
      return pos_in_queue * 10;
    },
    saveVideoConfig: function (data) {
      console.log("saving" + data["video_config"].api_key)
      Cache.setItem('api_key', data["video_config"].api_key);
      Cache.setItem('session', data["video_config"].session);
      Cache.setItem('token', data["video_config"].token);
    },
    enterSession: function () {
      //TODO: Check session is not expired before allowing user to enter
      router.push({
        name: "Video"
      })
    },
    abortSession: function () {
      this.session_abort = true
      Cache.clear()
      router.push({
        name: "CheckIn"
      })
    },
  },
  beforeDestroy() {
    //Kill polling on exit
    this.session_abort = true
  },
  created() {

  },
  mounted() {
    this.session_abort = false
    if (this.session_id == null) {
      alert("您的请求可能已过期，请重试");
      Cache.clear();
      router.push({
        name: "CheckIn"
      });
    } else {
      this.pollQueue()
    }
  }
};
</script>

<style scoped>
  #waitingroom {
    font-family: sans-serif;
  }

  h2 {
    background: #1bb1dc;
    padding: 20px 30px 15px 45px;
    margin: -30px -30px 30px -30px;
    border-radius: 10px 10px 0 0;
    -webkit-border-radius: 10px 10px 0 0;
    -moz-border-radius: 10px 10px 0 0;
    color: #fff;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.17);
    -webkit-box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.17);
    box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.17);
    border: 1px solid #1bb1dc;
    font-family: sans-serif;
    grid-column: 1/4;
  }

  .card {
    color: rgb(92, 91, 91);
    grid-column: 1/4;
    text-align: center;
  }

  button {
    background-color: #1bb1dc;
    /* Green */
    border: none;
    border-radius: 8px;
    color: white;
    padding: 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    grid-column: 2/3;
    margin-top: 30px;
  }

  button:hover {
    background: #1587a7;
  }

  #waitingroom {
    padding: 30px;
    margin: 40px auto;
    font-family: sans-serif;
    background: #FFF;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (min-width: 600px) {
    #waitingroom {
      width: 650px;
    }
  }

  p {
    font-size: 24px;
    font-weight: 800;
  }

  h4 {
    font-size: 20px;
    color: #1bb1dc;
    font-weight: 600;
  }

  #smallTip p {
    font-size: 16px;
    color: #1bb1dc;
  }
</style>